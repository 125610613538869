export const plotTextParagraphs = [
	"Guts is a lone warrior who was born from a hanged corpse and raised as a mercenary by his abusive adoptive father Gambino. As a child, Guts is trained by Gambino with adult-sized broadswords, causing him to develop a preference for oversized two-handed swords. One night, Gambino stumbles into Guts' tent armed and drunk, forcing Guts to kill him in self-defense. Guts flees his mercenary group and becomes a wandering sellsword. His fearsome reputation catches the attention of Griffith, the charismatic leader of a mercenary group known as the Band of the Hawk. Griffith forces Guts to join the group after defeating him in battle, with Guts becoming his best fighter and main confidante. The Band is hired by the kingdom of Midland to help in its century-long war against the Chuder Empire. Guts learns of Griffith's desire to rule a kingdom of his own and his mysterious pendant known as a Behelit. The Behelit is instrumental when they are spared by Nosferatu Zodd, a monstrous immortal who leaves Guts with a cryptic warning of a painful demise for being Griffith's friend.",
	"As Griffith begins to mingle with Midland nobility and acquaint himself with the king's daughter Charlotte, Guts begins developing feelings for his fellow commander Casca, the Hawks' only female member. When Guts overhears Griffith confessing to Charlotte that he only considers someone with their own dream to be a friend, Guts decides to leave the group once Midland has won its war. Griffith refuses Guts' departure and, believing he has no equal, challenges him to single combat. When Guts prevails and leaves to find his own dream, Griffith becomes emotionally distraught and reckless. He seduces Charlotte, resulting in his arrest and torture, and the Hawks are declared criminals by the Midland army. Guts, having spent a year training to become a better swordsman, is warned by a mysterious being (dubbed the 'Skull Knight') that his actions have instigated an 'Eclipse'. Learning of the Hawks' predicament, Guts rejoins them to rescue Griffith, while also consummating his feelings for Casca.",
	"They successfully rescue Griffith, but find that during the year of his capture, he was tortured incessantly, subjected to a glossectomy and had the tendons in his wrists and ankles severed. Casca and Guts try to determine what to do with him while making for Midland's borders. When Griffith regains his Behelit during a solar eclipse, the pendant activates, transporting the Hawks to a hell dimension where they encounter four archdemons — collectively known as the 'Godhand' — who have gathered for an event known as the Eclipse. Grffith has been chosen to transcend his humanity and become Femto — the fifth and final member of the Godhand. While despairing over his condition and being reminded of his ambitions, he willingly accepts the offer and sacrifices the Band of the Hawk. The Hawk are branded with a demonic sigil and slaughtered by the Godhand's Apostles — humans like Zodd who attained power by sacrificing their loved ones and humanity. Only Guts and Casca survive, but Griffith's first act as Femto is to rape Casca in front of Guts; Guts is enraged and tries unsuccessfully to save her, losing his right eye and left forearm in the attempt. They are rescued by the Skull Knight, but the ordeal traumatizes Casca, causing her to regress to a childlike state. Guts learns from the Skull Knight that their 'Brands of Sacrifice' will cause them to attract and be preyed upon by supernatural beings on a nightly basis. Guts leaves Casca in the care of the blacksmith Godo, his adoptive daughter Erica, and Rickert, the last and youngest member of the Band of the Hawk, who survived by not being present at the Eclipse. Godo outfits Guts with a giant new sword, 'Dragonslayer', and a prosthetic left arm with a built-in cannon and crossbow. Guts begins hunting down Apostles in search of revenge on Griffith while followed by a demonic phantom called the Demon Child - In actuality his and Casca's unborn child, deformed as a result of Femto's rape.",
	"Two years later, after having killed many Apostles and earning the nickname of 'the Black Swordsman', Guts is joined by an elf named Puck. They are captured by Farnese, captain of the Holy See Church's Holy Iron Chain Knights, who believes Guts is a foretold harbinger of the apocalypse. Guts escapes after saving Farnese from specters. Returning to Godo, Guts learns that Casca has disappeared. His search for her takes him to the refugee-infested city of St. Albion, a city the Godhand have prepared as the site for an Incarnation ceremony to give one of their own a physical form. Guts saves Casca from the fanatical Holy See bishop Mozgus as the city descends into nightmarish chaos from the souls of the dead attacking. Joined by Farnese, her bodyguard Serpico, and a young thief named Isidro, Guts and Casca survive the ordeal as a misshapen apostle living under St. Albion ingests the dying Demon Child and uses its body to reconstitute Griffith into a physical form.",
	"Guts encounters Griffith and Zodd at Godo's home and they engage in battle; the nearby enchanted mine, which sheltered Casca from the nightly demon attacks, is destroyed by Zodd while Griffith realizes some lingering traces of the Demon Child persist within him. To try and find a means of restoring her mind, Guts decides to journey with Casca to Puck's homeland of Elfhelm, on the island of Skellig. He allows Isidro, Farnese and Serpico to follow him out of fear that he will lose control of himself to his dark impulses — embodied as a demonic black dog within his mind — with Farnese becoming Casca's primary caretaker. At the same time, Griffith creates a second Band of the Hawk with Zodd and other Apostles to save Midland from the invading Kushan Empire, led by their rogue Apostle emperor, Ganishka.",
	"Guts' group encounters the witch Flora and her apprentice Shierke during an attempt to save a village from marauding trolls. Schierke begins to teach Farnese magic and dampens the effects of the sacrificial brands on Guts and Casca via magical seals. Flora gives Guts a dangerous relic known as the Berserker Armor, which increases his physical capability with the added risk of being consumed by his inner darkness. Flora is later killed when the New Band of the Hawk destroy the tree she made her home in. After escaping the destruction of Flora's home, during a full moon, Guts' party encounters Moonlight Boy, a small boy who does not speak but expresses a fondness for Guts and Casca. The boy disappears after the full moon passes. While Guts and his party secure a ship, captained by Farnese's erstwhile fiancée, Prince Roderick, to reach Elfhelm amidst a Kushan attack on the port, Griffith's war with Ganishka reaches its climax; the emperor is destroyed following his transition into a god-like abomination that causes an overlapping of the mortal realm and the supernatural astral realm. Unopposed and with Charlotte and the Holy See's blessing, Griffith establishes the city of Falconia to provide refuge for Midlanders and the rest of humanity from the numerous mythical creatures that manifested when the realms merged.",
	"After a dramatic battle with a supernatural island-sized monster known as the Sea God and recruiting a merrow girl named Isma, Guts' party reaches Elfhelm. The elf ruler, Danann, helps Farnese and Schierke travel into Casca's mind and restore her to her former self. Despite her recovery, Casca is still traumatized from her ordeal; she finds it difficult to be around Guts without remembering the events of the Eclipse. Farnese and Schierke begin training with the other apprentice witches and wizards living on Skellig, the former taking an interest in the power to heal human souls to help Casca. At the same time, the Skull Knight tells Guts his journey is at an end, introduces him to the creator of the Berserker Armor, and explains his own past with the Godhand. During another full moon, Moonlight Boy appears. Danann detects no malice from him and he is allowed to stay, forming a mother-son bond with Casca. It is revealed that Moonlight Boy is the Demon Child, who can take control of the body he shares with Griffith when the moon is full. When the current full moon wanes, Griffith resumes control of the shared body and is attacked by Guts until Zodd arrives. The arrival of the two incapacitates Casca and causes the ground beneath to erupt. This kills the island's giant spirit tree, unleashing a torrent of evil spirits and weakening Danann while Griffith carries Casca away. The torrent results in Elfhelm being almost completely submerged, with the survivors being either on Roderick's ship or on the remaining hilltop. Without the island and the spirit tree, Danann , Isma and the other magical creatures begin to disappear, unable to maintain their presence in the physical realm.",
];

export const gutsTexts = {
	intro: [
		"Guts, renowned as the 'Black Swordsman', is a former mercenary and branded wanderer who travels the world in a constant internal struggle between pursuing his own ends and upholding his attachments to those dear to him.",
		"At one time driven solely by his will to survive, Guts finds purpose in life upon joining the Band of the Falcon, greatly helping in the faction's storied successes during the Hundred-Year War as captain of the band's raiders. He eventually becomes dissatisfied with clinging to Griffith's dream and departs from the band in pursuit of his own.",
		"Following the horrors of the Eclipse prompted by Griffith, Guts embarks on a two-year war against the God Hand and apostles, becoming increasingly embittered on his one-track quest for retribution. He in time realizes protecting his regressed lover is more important to him, as well as something he cannot do alone, and thus puts aside his quest for revenge while fostering camaraderie with new companions.",
	],
};

export const griffithTexts = {
	intro: [
		"Griffith is the current leader of the reborn Band of the Hawk and supreme commander of the Midland Regular Army. Having been at the fore of many battles, he has amassed a reputation as a savior across the continent, and is revered as the 'Falcon of Light'.",
		"As the leader of the original Band of the Hawk, he and his mercenaries are able to end the Hundred-Year War and bring peace to the war-torn kingdom of Midland. Following the desertion of his most trusted confidant Guts, Griffith beds Princess Charlotte on impulse, bringing about his imprisonment, year-long crippling torture, and the seeming demise of his dream. Though he is eventually rescued by the band, his state of extreme invalidity and undying desire to obtain his own kingdom compel him to sacrifice his comrades during the fifth Eclipse, for kinsmanship with the God Hand as their fifth member Femto – a demonic, idealized version of himself stripped of his human inhibitions. In time, he is incarnated into the Physical World, concerned solely with the realization of his dream, which comes to pass with his thwarting of the Kushan invasion of Midland, the Great Roar of the Astral World he prompts, and the rise of his utopian capital city, Falconia.",
	],
};

export const cascaTexts = {
	intro: [
		"Casca is a former unit commander of the Band of the Hawk and an estranged companion of Guts.",
		"Originally a callow peasant girl living in a remote mountain village, she develops into a well-attuned warrior upon joining the Band of the Hawk and aids greatly in the group's storied successes during the Hundred-Year War. Following Guts' departure and Griffith's subsequent imprisonment, she is forced to assume the position as the band's leader, succeeding in preventing the complete annihilation of the Band of the Hawk and rescuing Griffith from the Tower of Rebirth. Crippled from his horrifying year-long torture and demoralized by his fall from grace, Griffith ultimately invokes the Eclipse, in which he is reborn as the fifth God Hand member Femto and rapes Casca to spite Guts. Having physically survived the ordeal, she regresses into an infantile and largely mute amnesiac.",
		"Guts and his newly formed traveling party are currently preoccupied with the restoration of her fragmented mind in Elfhelm.",
	],
};

export const zoddTexts = {
	intro: [
		"Zodd is a legendary swordsman and powerful apostle said to have killed thousands of adversaries on the battlefield. After intervals of inactivity and purported death, Zodd resurfaces on the battlefield to continue his slaughter, having done so unmitigated for roughly 300 years.",
		"He possesses an intense thirst for battle only sated by his long-spanning rivalry with the Skull Knight. His search for the 'ultimate strong one' leads him to submit to an incarnated Griffith, whom he serves as one of the White Falcon's most trusted warriors in the reborn Band of the Hawk.",
	],
};
